import { QueryFunctionContext } from "@tanstack/query-core";
import axios, { AxiosError } from "axios";
import { ACCIDENT, documentsTypesDict, FORM, SCAN } from "../const/documentsTypesDict";
import { ChartDataDTO } from "../dto/ChartDTO";
import { ResearchDatesDTO } from "../dto/ResearchDatesDTO";
import ResearchDTO from "../dto/ResearchDTO";
import { DocumentTypeForEditResearchDTO, ResearchForEditDTO, ResearchUserDTO } from "../dto/ResearchEditDTO";
import { ResearchViewDTO } from "../dto/ResearchViewDTO";
import { LooseObject } from "../util/LooseObject";
import { useQuery } from "@tanstack/react-query";

export async function getResearchesByUser(): Promise<ResearchDTO[]> {
    const response = await axios.get(`/api/Researches/GetResearchesByUser`);
    return (response.data.result.researches as ResearchDTO[]);
}

export const useGetResearch = (
    researchId: number | null | undefined,
    onSuccess?: (data: ResearchDTO) => void,
    onError?: (error: AxiosError<string, any>) => void
    ) => {
    return useQuery<ResearchDTO, AxiosError<string, any>>({
        queryKey: ["Researches", "GetResearch", researchId],
        queryFn: async () => {
            return (await
                axios.get(`/api/Researches/GetResearch?id=${researchId}`))
                .data;
        },
        enabled: !!researchId,
        onSuccess: onSuccess,
        onError: onError
    });
};

export async function getCharts(context: QueryFunctionContext): Promise<ChartDataDTO[][]> {
    const response = await axios.get(`api/Researches/GetCharts?researchId=${context.queryKey[2] ?? ""}`);
    return (response.data.result.data as ChartDataDTO[][]);
}

export async function getResearchDates(context: QueryFunctionContext): Promise<ResearchDatesDTO> {
    const response = await axios.get(`api/Researches/GetResearchDate/${context.queryKey[2]}`);
    return (response.data.result as ResearchDatesDTO);
}

export async function editResearchStatus(params: { id: number, isOpen: boolean }) {
    const response = await axios.post(`/api/Researches/ChangeStatus?id=${params.id}&isOpen=${params.isOpen}`);
    return (response.data.result);
}

export async function getResearchForEdit(context: QueryFunctionContext): Promise<ResearchForEditDTO> {
    const response = await axios.get('api/Researches/GetResearch', { params: { id: context.queryKey[2] } });
    const researchForEdit: ResearchForEditDTO = {
        ...response.data,
    };
    const currentFormsFormat = response.data.forms.map((f: DocumentTypeForEditResearchDTO, index: number) => {
        return {
            ...f,
            order: ++index,
            deadline: f.deadline,
            typeId: documentsTypesDict[FORM]
        }
    });
    const currentScansFormat = response.data.scans.map((id: number, index: number) => {
        return {
            id: id,
            order: ++index,
            typeId: documentsTypesDict[SCAN]
        } as DocumentTypeForEditResearchDTO
    });
    researchForEdit.documentTypes = currentFormsFormat.concat(currentScansFormat);
    const currentUsersFormat = response.data.users.map((u: string) => {
        return {
            id: u
        } as ResearchUserDTO
    });
    researchForEdit.users = currentUsersFormat;
    const currentDataManagersFormat = response.data.dataManagers.map((dm: string) => {
        return {
            id: dm
        } as ResearchUserDTO
    });
    researchForEdit.dataManagers = currentDataManagersFormat;

    return researchForEdit;
}

export async function editResearch(
    data: {
        research: ResearchForEditDTO,
        scans: DocumentTypeForEditResearchDTO[] | undefined,
        forms: DocumentTypeForEditResearchDTO[] | undefined
    }
): Promise<number> {
    const researchForEdit: LooseObject = {
        allDocumentsRequired: data.research.allDocumentsRequired,
        dateEnd: data.research.dateEnd,
        dateStart: data.research.dateStart,
        description: data.research.description,
        documentTypes: data.scans!
            .concat(data.forms!)
            .filter(d => d.selected)
            .concat(data.research.documentTypes)
            .map((d) => {
                if (d.typeId === documentsTypesDict[ACCIDENT]) {
                    return {
                        date: d.date,
                        documentTypeId: d.id
                    }
                } else if (d.typeId === documentsTypesDict[FORM]) {
                    return {
                        date: d.date,
                        documentTypeId: d.id,
                        deadlineDate: d.deadline ?? null,
                        order: d.order ?? null,
                        required: d.required ?? false 
                    }
                } else {
                    return {
                        date: d.date,
                        documentTypeId: d.id,
                        deadlineDate: d.deadline ?? null,
                        order: d.order ?? null,
                    }
                }
            }),
        hideScanDocuments: data.research.hideScanDocuments,
        id: data.research.id,
        name: data.research.name,
        patientPerDoctor: data.research.patientPerDoctor,
        patientPlannedCount: data.research.patientPlannedCount,
        shortName: data.research.shortName,
        users: data.research.dataManagers.concat(data.research.users).map((u) => {
            return {
                researchId: data.research.id,
                userId: u.id
            }
        }),
        workflowEnabled: data.research.workflowEnabled
    }
    if (data.research.id === 0) {
        const response = await axios.post(`api/Researches/AddResearch`, { ...researchForEdit });
        return (response.data.result.id as number);
    } else {
        const response = await axios.post(`api/Researches/EditResearch`, { ...researchForEdit });
        return (response.data.result.id as number);
    }
}

export const useGetResearchView = (
    researchId: number | null | undefined
) => {
    return useQuery<ResearchViewDTO, AxiosError>({
        queryKey: ["Researches", "GetResearch", researchId],
        queryFn: async () => {
            return (await
                axios.get(`/api/Researches/GetResearch?id=${researchId}`))
                .data;
        },
        enabled: !!researchId
    });
};

export async function getAgents(id: number) {
    const response = await axios.get(`api/Researches/GetAgents?researchId=${id}`);
    return response.data.result.agents;
}
