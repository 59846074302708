import { LooseObject } from "../util/LooseObject";

export const DOCTOR_ROLE = 'DOCTOR';
export const ADMIN_ROLE = 'ADMIN';
export const SUPER_ADMIN_ROLE = 'SUPER_ADMIN';
export const AGENT_ROLE = 'AGENT';
export const CURATOR_ROLE = 'CURATOR';
export const DATA_ROLE = 'DATA';
export const ANALYST_ROLE = 'ANALYST';
export const SAFETY_ROLE = 'SAFETY';

export const RuRoles: LooseObject = {
    [DOCTOR_ROLE]: "Врач",
    [ADMIN_ROLE]: "Администратор",
    [SUPER_ADMIN_ROLE]: "Суперадмин",
    [AGENT_ROLE]: "Представитель",
    [CURATOR_ROLE]: "Куратор",
    [DATA_ROLE]: "Дата-менеджер",
    [ANALYST_ROLE]: "Аналитик",
    [SAFETY_ROLE]: "Фармакобезопасность"
}