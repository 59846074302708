import React, { useCallback, useState } from 'react';
import { Avatar, Badge, Box, Divider, IconButton, Menu, Tooltip, Typography } from "@mui/material";
import { Announcement, AssignmentLate, Error, Inbox, Notifications } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getLastNotifications, readNotification } from '../../queries/Notifications';
import { useNavigate } from 'react-router-dom';
import NotificationDTO from '../../dto/NotificationDTO';
import { ADMIN_ROLE } from '../../const/roles';
import { DATE_TIME_FORMAT } from '../../const/common';
import { useRole } from '../../util/useRole';

const NotificationsMenu = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const role = useRole();

    const [notificationsMenuOpen, setNotificationsMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleNotificationsMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setNotificationsMenuOpen(true);
    }, [])

    const handleNotificationsMenuClose = useCallback(() => {
        setAnchorEl(null);
        setNotificationsMenuOpen(false);
    }, [])

    const notificationsQuery = useQuery(["Notifications", "Last"], getLastNotifications);

    const readNotificationMutation = useMutation(readNotification, {
        onSuccess: (response, params) => {
            queryClient.setQueryData(["Notifications", "Last"], (oldData?: NotificationDTO[]) => {
                if (!oldData) {
                    return undefined;
                }

                return oldData.filter(n => n.id !== params);
            });
        }
    });

    const handleNotificationClick = useCallback((notification: NotificationDTO) => {
        if (notification.taskId || notification.feedbackId) {
            readNotificationMutation.mutate(notification.id);
            navigate("/profile", { state: { notification } });
        }
        else if (notification.accidentId != null) {
            readNotificationMutation.mutate(notification.id);
            navigate(`/document/view/${notification.patientId}?documentTypeId=${notification.documentTypeId}&accident=true`);
        }
        handleNotificationsMenuClose();
    }, [handleNotificationsMenuClose, navigate, readNotificationMutation]);

    return <>
        <Tooltip
            title='Уведомления'
        >
            <IconButton
                onClick={handleNotificationsMenuOpen}
                color='inherit'
            >
                <Badge
                    color='error'
                    badgeContent={(notificationsQuery.data ?? []).length}
                    invisible={(notificationsQuery.data ?? []).length === 0}
                >
                    <Notifications
                        sx={{
                            fontSize: 30
                        }}
                    />
                </Badge>
            </IconButton>
        </Tooltip>
        <Menu
            open={notificationsMenuOpen}
            onClose={handleNotificationsMenuClose}
            anchorEl={anchorEl}
            sx={{
                overflowX: 'hidden',
                padding: '8px 24px',
                overflow: 'visible',
                marginTop: "4px"
            }}
        >
            <Typography
                variant='subtitle1'
                sx={{
                    outline: 'none',
                    paddingX: 4,
                    paddingY: 1
                }}
            >
                Уведомления
            </Typography>
            {
                (notificationsQuery.data ?? []).length === 0 ?
                    <Box
                        width={400}
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        padding="0 48px"
                    >
                        <Inbox
                            color='primary'
                            sx={{
                                fontSize: 120,
                                marginTop: 4,
                            }}
                        />
                        <Typography
                            variant='body2'
                            align="center"
                            sx={{
                                fontWeight: "bold",
                                marginTop: 4,
                            }}>
                            Пока ничего нового
                        </Typography>
                        <Typography
                            variant='body2'
                            align="center"
                            sx={{ marginBottom: 4 }}
                        >
                            Здесь отображаются уведомления, связанные с вами. Пока ничего нет, проверьте позже
                        </Typography>
                    </Box> :
                    <Box width={400}>
                        {
                            (notificationsQuery.data ?? []).map(n => <React.Fragment key={n.id}>
                                <Divider />
                                <Box
                                    sx={{
                                        outline: "none",
                                        cursor: "pointer",
                                        "&: hover": {
                                            backgroundColor: "rgba(0, 0, 0, 0.07)"
                                        }
                                    }}
                                    onClick={() => handleNotificationClick(n)}
                                >
                                    <Box
                                        paddingY={1}
                                        paddingX={4}
                                        display="flex"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Badge
                                            sx={{
                                                marginRight: 0,
                                                backgroundColor: "transparent"
                                            }}
                                            badgeContent={n.typeId === 0 ? <Error style={{ color: "#c00000" }} /> :
                                                n.typeId === 1 ? <AssignmentLate style={{ color: "#ffc107" }} /> :
                                                    <Announcement style={{ color: "#aaa" }} />}
                                        >
                                            <Avatar
                                                sx={theme => ({
                                                    color: theme.palette.secondary.main,
                                                    backgroundColor: "#eee",
                                                    fontSize: "1rem"
                                                })}
                                            >
                                                АУ
                                            </Avatar>
                                        </Badge>
                                        <Box ml={2}>
                                            {
                                                n.taskId !== null &&
                                                <Typography>
                                                    {n.text}
                                                </Typography>
                                            }
                                            {
                                                n.feedbackId !== null && (role === ADMIN_ROLE ?
                                                    <Typography>
                                                        Поступило новое обращение № <b>{n.feedbackId}</b>
                                                    </Typography>
                                                    :
                                                    <Typography>
                                                        Поступила обратная связь по вашему обращению № <b>{n.feedbackId}</b>
                                                    </Typography>)
                                            }
                                            {
                                                n.accidentId !== null &&
                                                <Typography>
                                                    {n.text}
                                                </Typography>
                                            }
                                            <Typography style={{ fontWeight: "bold" }}>
                                                {n.typeId === 0 || n.typeId === 2 ? n.fromUser : "Автоматическое уведомление"}
                                            </Typography>
                                            <Typography variant='body1' color='textSecondary'>
                                                {new Date(n.date).toLocaleString('ru-RU', DATE_TIME_FORMAT)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </React.Fragment>)
                        }
                    </Box>
            }
        </Menu>
    </>;
}

export default NotificationsMenu;
