import { AlertColor } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, Suspense, useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import { ClientSettingsDTO } from './dto/ClientSettingsDTO';
import UserRequestDTO from './dto/UserRequestDTO';

export interface IApplicationContext {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showMessage: (text: string, type: AlertColor) => void;
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUserContext {
    user: UserRequestDTO | null;
    setUser: React.Dispatch<React.SetStateAction<UserRequestDTO | null>>;
}

export interface IClientSettingsContext {
    clientSettings: ClientSettingsDTO | null;
    setClientSettings: React.Dispatch<React.SetStateAction<ClientSettingsDTO | null>>;
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        }
    }
});


export const ApplicationContext = React.createContext<IApplicationContext | null>(null);
export const UserContext = React.createContext<IUserContext | null>({ user: null, setUser: () => { } });
export const ClientSettingsContext = React.createContext<IClientSettingsContext | null>(null);

const Login = lazy(() => import('components/Login/Login'));
const ResearchRedirect = lazy(() => import('components/Researches/ResearchRedirect'));
const DocumentRedirect = lazy(() => import('components/Documents/Document/Document'));
const DocumentTypesRedirect = lazy(() => import('components/Documents/DocumentTypes/DocumentTypes'));
const DocumentScansRedirect = lazy(() => import('components/Documents/DocumentScans/DocumentScans'));
const ResearchEdit = lazy(() => import('components/Researches/ResearchEdit/ResearchEdit'));
const Users = lazy(() => import('components/Users/Users'));
const User = lazy(() => import('components/Users/User/User'));
const Logs = lazy(() => import('components/Logs/Logs'));
const Settings = lazy(() => import('components/Settings/Settings'));
const AccessForbidden = lazy(() => import('components/Layout/AccessForbidden'));
const ResearchView = lazy(() => import('components/Researches/ResearchView/ResearchView'));
const Profile = lazy(() => import('components/Profile/Profile'));
const Charts = lazy(() => import('components/Analytics/Charting/Charts')); 
const ResearchDownload = lazy(() => import('components/Analytics/ResearchDownload'));
const PatientEdit = lazy(() => import("./components/Patients/PatientEdit"));
const DocumentType = lazy(() => import("./components/Documents/DocumentType/DocumentType"));

function App() {

    const [loading, setLoading] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<{ text: string, type: AlertColor } | null>(null);

    const [user, setUser] = useState<UserRequestDTO | null>(null);
    const [userRole, setUserRole] = useState<string[] | null>(null);
    const [clientSettings, setClientSettings] = useState<ClientSettingsDTO | null>(null);


    const showMessage = useCallback((text: string, type: AlertColor) => {
        setMessage({ text, type });
    }, [setMessage]);

    const hideMessage = useCallback(() => {
        setMessage(null);
    }, [setMessage]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale='ru'
        >
            <ClientSettingsContext.Provider
                value={{
                    clientSettings,
                    setClientSettings
                } as IClientSettingsContext}
            >
                <UserContext.Provider
                    value={{
                        user,
                        setUser,
                        userRole,
                        setUserRole
                    } as IUserContext}
                >
                    <ApplicationContext.Provider
                        value={{
                            loading,
                            setLoading,
                            showMessage,
                            drawerOpen,
                            setDrawerOpen
                        } as IApplicationContext}
                    >
                        <DndProvider backend={HTML5Backend}>
                            <QueryClientProvider
                                client={queryClient}
                            >
                                <Layout
                                    message={message}
                                    hideMessage={hideMessage}
                                >
                                    <Suspense>
                                        <Routes>
                                            <Route path='/login' element={<Login />} />
                                            <Route path='/' element={<ResearchRedirect />} />
                                            <Route path='/document/:formType/:patientId' element={<DocumentRedirect />} />
                                            <Route path='/documentTypes' element={<DocumentTypesRedirect />} />
                                            <Route path='/documentScans' element={<DocumentScansRedirect />} />
                                            <Route path='/research/:type/:id?' element={<ResearchEdit />} />
                                            <Route path='/users' element={<Users />} />
                                            <Route path='/user/:type/:id?' element={<User />} />
                                            <Route path='/logs' element={<Logs />} />
                                            <Route path='/settings' element={<Settings />} />
                                            <Route path='/access_forbidden' element={<AccessForbidden />} />
                                            <Route path='/researchview/:id' element={<ResearchView />} />
                                            <Route path='/profile' element={<Profile />} />
                                            <Route path='/charts' element={<Charts />} />
                                            <Route path='/researchdownload/:id' element={<ResearchDownload />} />
                                            <Route path='/patient/:type/:id' element={<PatientEdit />} />
                                            <Route path='/documentType/edit/:id' element={<DocumentType />} />
                                        </Routes>
                                    </Suspense>
                                </Layout>
                            </QueryClientProvider>
                        </DndProvider>
                    </ApplicationContext.Provider>
                </UserContext.Provider>
            </ClientSettingsContext.Provider>
        </LocalizationProvider>
    );
}

export default App;
