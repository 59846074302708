import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import { AGENT_ROLE, DATA_ROLE } from "../const/roles";
import CuratorProfileDTO from "../dto/CuratorProfileDTO";
import DoctorProfileDTO from "../dto/DoctorProfileDTO";
import { DoctorWithAccidentDTO } from "../dto/DoctorWithAccidentDTO";
import { ResearchDoctorDTO } from "../dto/ResearchDoctorDTO";
import { ResearchUserDTO } from "../dto/ResearchEditDTO";
import { AgentDTO, UserEditDTO } from "../dto/UsersDTO";
import { resolveBlob } from "../util/common";

export async function getHelp(): Promise<File> {
    const response = await axios.get(`api/Users/GetHelp`, { responseType: "blob" });
    return resolveBlob(response);
}

export async function getCuratorProfile(): Promise<CuratorProfileDTO> {
    const response = await axios.get(`api/Users/GetCuratorProfile`);
    return (response.data.result as CuratorProfileDTO);
}

export async function getDoctorProfile(): Promise<DoctorProfileDTO> {
    const response = await axios.get(`api/Users/GetDoctorProfile`);
    return (response.data.result as DoctorProfileDTO);
}

export async function getResearchDoctors(context: QueryFunctionContext): Promise<ResearchDoctorDTO[]> {
    const response = await axios.get(`/api/Users/GetDoctorsByResearch?id=${context.queryKey[2]}`);
    return (response.data.result.doctors as ResearchDoctorDTO[]);
}

export async function getDoctorsWithAccidents(context: QueryFunctionContext): Promise<DoctorWithAccidentDTO[]> {
    const response = await axios.get(`/api/Users/GetDoctorsWithAccidents?researchId=${context.queryKey[2]}`);
    return (response.data.result.doctors as DoctorWithAccidentDTO[]);
}

export async function getUsersForResearchEdit() {
    const response = await axios.get(`/api/Users/GetUsers`);

    const agents = response.data.result.users.filter((user: ResearchUserDTO) =>
        user.role === AGENT_ROLE
    );

    const managers = response.data.result.users.filter((user: ResearchUserDTO) =>
        user.role === DATA_ROLE
    );

    return ({ agents: agents, managers: managers });
}

export async function fetchAgentDoctors(params: { id: string, researchId: number }) {
    const response = await axios.get(`api/Users/GetAgentDoctors`, { params });
    return response.data.result.doctors;
}

export async function getCurrentAgentDoctors(id: number) {
    const response = await axios.get(`api/Users/GetCurrentAgentDoctors?researchId=${id}`);
    return response.data.result.doctors;
}

export async function getUsersForEditTableData(params: QueryFunctionContext) {
    const response = await axios.get(`api/Users/GetUser`, { params: { id: params.queryKey[1] } });
    return response.data.result;
};

export async function deleteUser(id: string) {
    return await axios.post(`api/Users/DeleteUser?id=${id}`);
};

export async function changePassword(params: { id: string, sendSms: boolean, sendMail: boolean, password: string }) {
    return await axios.post(`api/Users/ChangePassword`, { ...params });
};

export async function getAgentsForUserEdit(): Promise<AgentDTO[]> {
    const response = await axios.get(`/api/Users/GetUsers`);
    return (response.data.result.users as AgentDTO[]).filter(a => a.role === AGENT_ROLE);
}

export async function postUserForEdit(params: { type: string, sendSms: boolean, sendMail: boolean, userData: UserEditDTO }) {
    const userForm = {
        id: params.userData.user.id ?? null,
        name: params.userData.user.name,
        surname: params.userData.user.surname,
        middlename: !!params.userData.user.middlename?.length ?
            params.userData.user.middlename :
            "",
        role: params.userData.role,
        email: params.userData.user.email,
        agents: params.userData.agents,
        phoneNumber: !!params.userData.user.phoneNumber?.length ?
            params.userData.user.phoneNumber :
            "",
        password: params.userData.user.password,
        sendMail: params.sendMail,
        sendSms: params.sendSms,
        blocked: params.userData.user.blocked
    };

    const response = await axios.post(
        params.type === 'add' ?
            `/api/Users/AddUser` :
            `/api/Users/EditUser`,
        {
            ...userForm
        }
    );
    return response;
}