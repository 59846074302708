import { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import { GetRole } from "./common";

export function useRole() {
    const { user } = useContext(UserContext)!;
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        if (user?.id === null) {
            setRole(null);
        } else {
            setRole(GetRole());
        }
    }, [user?.id]);

    return role;
}
