export const ACCIDENT = 'ACCIDENT';
export const SCAN = 'SCAN';
export const FORM = 'FORM'
export const LIGAL = 'LIGAL'

export const documentsTypesDict = {
    [FORM]: 0,
    [LIGAL]: 1,
    [ACCIDENT]: 2,
    [SCAN]: 3,
}