import { AccountCircle } from '@mui/icons-material';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Menu, Tooltip, Typography } from "@mui/material";
import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationContext, IApplicationContext, IUserContext, UserContext } from '../../App';
import { ADMIN_ROLE, CURATOR_ROLE, DOCTOR_ROLE, SUPER_ADMIN_ROLE } from '../../const/roles';
import { signOutPost } from '../../queries/Login';
import { GetRuRole } from '../../util/common';
import { useRole } from '../../util/useRole';

const UserMenu = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const [signOutModalOpen, setSignOutModalOpen] = useState<boolean>(false);
    const role = useRole();

    const usrCtx = useContext<IUserContext | null>(UserContext);
    const appCtx = useContext<IApplicationContext | null>(ApplicationContext);

    const navigate = useNavigate();

    const signOutQuery = useMutation<AxiosResponse, AxiosError, { email: string }>(signOutPost, {
        onMutate: () => {
            appCtx?.setLoading(true);
        },
        onSettled: () => {
            appCtx?.setLoading(false);
        },
        onSuccess: () => {
            localStorage.removeItem('accessToken');
            usrCtx!.setUser(null);
            appCtx?.setLoading(false);
            navigate(`/login`);
        }
    });

    const handlePersonalAreaClick = useCallback(() => {
        navigate("/profile");
        setAnchorEl(null);
        setUserMenuOpen(false);
    }, [navigate]);

    const handleUserMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setUserMenuOpen(true);
    }, [])

    const handleUserMenuClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null);
        setUserMenuOpen(false);
    }, [])

    const handleSignOutModalOpen = useCallback(() => {
        setSignOutModalOpen(true);
        setAnchorEl(null);
        setUserMenuOpen(false);
    }, [setSignOutModalOpen]);

    const handleSignOutModalClose = useCallback(() => {
        setSignOutModalOpen(false);
    }, [setSignOutModalOpen]);

    const handleSignOutButtonClick = useCallback(() => {
        setSignOutModalOpen(false);
        signOutQuery.mutate({ email: usrCtx!.user!.userName });
    }, [signOutQuery, usrCtx])

    return (
        <>
            <Tooltip
                title='Пользователь'
            >
                <IconButton
                    onClick={handleUserMenuOpen}
                    color='inherit'
                >
                    <AccountCircle
                        sx={{
                            fontSize: 30
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                open={userMenuOpen}
                onClose={handleUserMenuClose}
                anchorEl={anchorEl}
                sx={{
                    overflowX: 'hidden',
                    padding: '8px 24px',
                    marginTop: 2,
                    overflow: 'visible',
                    marginRight: 4,
                }}
            >
                <Box
                    width={340}
                >
                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='start'
                        alignItems='center'
                        p={2}
                    >
                        <Avatar
                            sx={{
                                color: '#ffffff',
                                backgroundColor: "#24226a",
                                fontSize: "1rem"
                            }}
                        >
                            {usrCtx!.user!.surname[0] + usrCtx!.user!.name[0]}
                        </Avatar>
                        <Box
                            ml={2}
                        >
                            <Typography
                                color='primary'
                            >
                                {usrCtx!.user!.surname} {usrCtx!.user!.name}
                            </Typography>
                            <Typography>
                                {`${GetRuRole()}${role === DOCTOR_ROLE ? " КОД: " + usrCtx!.user!.code : ""}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        px={1}
                        pt={1}
                    >
                        {
                            [DOCTOR_ROLE, ADMIN_ROLE, SUPER_ADMIN_ROLE, CURATOR_ROLE].includes(role ?? "") &&
                                <Button
                                    onClick={handlePersonalAreaClick}
                                    color='primary'
                                >
                                    Личный кабинет
                                </Button>
                        }
                        <Button
                            onClick={handleSignOutModalOpen}
                            color="inherit"
                        >
                            Выйти
                        </Button>
                    </Box>
                </Box>
            </Menu>
            <Dialog
                open={signOutModalOpen}
                onClose={handleSignOutModalClose}
            >
                <DialogTitle>
                    Выход
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Выйти из системы?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        width="100%"
                    >
                        <Button
                            onClick={handleSignOutModalClose}
                            color='inherit'
                        >
                            Отмена
                        </Button>
                        <Button
                            onClick={handleSignOutButtonClick}
                            color='primary'
                        >
                            Выйти
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserMenu;