import axios from "axios";
import { ClientSettingsDTO } from "../dto/ClientSettingsDTO";
import { SettingsDTO } from "../dto/SettingsDTO";

export async function getSettings() {
    const response = await axios.get(`api/Settings/GetSettings`);
    return response.data.result.settings as SettingsDTO;
};

export async function saveSettings(settings: SettingsDTO) {
    const response = await axios.post(`api/Settings/SaveSettings`, { ...settings });
    return response.data.result.settings as SettingsDTO;
};

export async function getClientSettings() {
    const response = await axios.get(`api/Settings/GetClientSettings`);
    return (response.data.result.clientSettings as ClientSettingsDTO)!;
};